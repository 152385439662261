<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Publiceer evenement</h1>
        <p class="text-muted">Controleer de onderstaande gegevens. Klopt alles? klik dan rechtsonderin op publiceren.</p>
        
        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5">Evenement gegevens</h3>
            </b-card-header>
            <b-card-body>
                <b-row cols="1" cols-sm="2">
                    <b-col>
                        <dt class="text-muted">Datum</dt>
                        <dd>
                            <template v-if="event.is_multiday_ticket">
                                Meerdere datums
                            </template>
                            <template v-else>
                                <template v-if="event.slots">
                                    <template v-if="isSameDayEvent(event)">
                                        {{ event.slots[0].start_at|formatTextDate }}
                                    </template>
                                    <template v-else>
                                        {{ event.slots[0].start_at|formatTextDate }} - {{ event.slots[0].end_at|formatTextDate }}
                                    </template>
                                </template>
                            </template>
                        </dd>
                    </b-col>
                    <b-col>
                        <dt class="text-muted">Locatie</dt>
                        <dd>
                            <template v-if="event.location">
                                {{ event.location.name }}, {{ event.location.place }}
                            </template>
                            <template v-else>
                                Geen
                            </template>
                        </dd>
                    </b-col>
                    <b-col>
                        <dt class="text-muted">Start verkoop</dt>
                        <dd>{{ event.start_sale_at|formatTextDate }}</dd>
                    </b-col>
                    <b-col>
                        <dt class="text-muted">Stop verkoop</dt>
                        <dd>{{ event.end_sale_at|formatTextDate }}</dd>
                    </b-col>
                    <b-col>
                        <dt class="text-muted">Klant betaalt transactiekosten</dt>
                        <dd>
                            <template v-if="event.is_customer_transaction_fee">Ja: {{ event.customer_transaction_fee|toCurrency }}</template>
                            <template v-else>Nee</template>
                        </dd>
                    </b-col>
                    <b-col>
                        <dt class="text-muted">Klant betaalt servicekosten</dt>
                        <dd>
                            <template v-if="event.is_customer_ticket_fee">Ja: {{ event.customer_ticket_fee|toCurrency }}</template>
                            <template v-else>Nee</template>
                        </dd>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer>
                <b-button variant="primary" :to="`/manage/events/` + this.$route.params.id + `/settings/event`">
                    Wijzigen
                </b-button>
            </b-card-footer>
        </b-card>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5">Tickets</h3>
            </b-card-header>
            <b-card-body>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Prijs</th>
                            <th>Start/stop verkoop</th>
                            <th>Start/stop geldigheid</th>
                            <th>Limiet</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(ticket, key) in event.tickets" :key="key">
                            <td>
                                <div>{{ ticket.name }}</div>
                                <div class="text-muted">
                                    <template v-if="ticket.type == 'ticket'">
                                        Toegangsticket
                                    </template>
                                    <template v-else>
                                        Voucher
                                    </template>
                                </div>
                            </td>
                            <td>
                                {{ ticket.price|toCurrency }}
                            </td>
                            <td>
                                {{ ticket.start_sale_at|formatDateTime }} - {{  ticket.end_sale_at|formatDateTime }}
                            </td>
                            <td>
                                {{ ticket.start_valid_at|formatDateTime }} - {{  ticket.end_valid_at|formatDateTime }}
                            </td>
                            <td>
                                <template v-if="ticket.amount_available > 0">
                                    {{ ticket.amount_available }}
                                </template>
                                <template v-else>
                                    Geen
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-card-body>
            <b-card-footer>
                <b-button variant="primary" :to="`/manage/events/` + this.$route.params.id + `/settings/tickets`">
                    Wijzigen
                </b-button>
            </b-card-footer>
        </b-card>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5">Thema</h3>
            </b-card-header>
            <b-card-body>
                <!-- <div class="text-muted">Je hebt het thema aangepast voor dit evenement.</div> -->
                <div class="text-muted">Je gebruikt het standaard webshop thema.</div>
            </b-card-body>
            <b-card-footer>
                <b-button variant="primary" :to="`/manage/events/` + this.$route.params.id + `/settings/theme`">
                    Wijzigen
                </b-button>
            </b-card-footer>
        </b-card>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5 mb-0">Klantgegevens</h3>
                <div class="text-muted">Je wilt de onderstaande gegevens weten van je klanten.</div>
            </b-card-header>
            <b-card-body>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Veld</th>
                            <th>Verplicht</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in extraDetailsOptions" :key="key">
                            <td>{{ item.text }}</td>
                            <td>
                                <template v-if="event.extra_details_required && event.extra_details_required.includes(item.value)">
                                    Ja
                                </template>
                                <template v-else>
                                    Nee
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-card-body>
            <b-card-footer>
                <b-button variant="primary" :to="`/manage/events/` + this.$route.params.id + `/settings/personal-data`">
                    Wijzigen
                </b-button>
            </b-card-footer>
        </b-card>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5">Persoonlijke tickets</h3>
                <div class="text-muted">Je maakt gebruik van persoonlijke tickets met de onderstaande gegevens.</div>
            </b-card-header>
            <b-card-body>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Veld</th>
                            <th>Verplicht</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in extraDetailsOptions" :key="key">
                            <td>{{ item.text }}</td>
                            <td>
                                <template v-if="event.extra_details_required && event.extra_details_required.includes(item.value)">
                                    Ja
                                </template>
                                <template v-else>
                                    Nee
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-card-body>
            <b-card-footer>
                <b-button variant="primary" :to="`/manage/events/` + this.$route.params.id + `/settings/personal-data`">
                    Wijzigen
                </b-button>
            </b-card-footer>
        </b-card>

        <div class="clearfix">
            <b-button @click="publishEvent" variant="primary" class="float-right">
                Publiceren
                <font-awesome-icon icon="fa-solid fa-chevron-right" />
            </b-button>
        </div>

      </b-container>
    </div>
  </template>
  
  <script>
    export default {
        metaInfo: {
            title: 'Publiceer evenement',
        },
        components: {},
        data() {
            return {
                event: {},
                extraDetailsOptions: [
                    { text: 'Bedrijfsnaam', value: 'company_name' },
                    { text: 'Geboortedatum', value: 'birthdate' },
                    { text: 'Geslacht', value: 'gender' },
                    { text: 'Telefoonnummer', value: 'phone' },
                    { text: 'Adres', value: 'address' },
                    { text: 'Postcode', value: 'zipcode' },
                    { text: 'Woonplaats ', value: 'city ' },
                ],
                personalDetailRequiredOptions: [
                    { text: 'E-mail', value: 'email' },
                    { text: 'Telefoonnummer', value: 'phone' },
                    { text: 'Geboortedatum', value: 'birthdate' },
                    { text: 'Geslacht', value: 'gender' },
                ],
            }
        },
        computed: {},
        methods: {
            fetchEvent: function() {
                let id = this.$route.params.id;
                this.$axios.get("https://api.tixgo.nl/events/" + id)
                    .then( response => {
                        if (response.data) {
                            this.event = response.data;
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            publishEvent: function() {
                this.$axios.put("https://api.tixgo.nl/events/" + this.$route.params.id + '/publish')
                    .then( response => {
                        response;
                        this.$router.push('/manage/events');
                    })
                ;
            },
            isSameDayEvent(event) {
                if (event.slots) {
                    let d1 = new Date(event.slots[0].start_at);
                    let d2 = new Date(event.slots[0].end_at);
                    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
                }
            },
        },
        created() {
            this.fetchEvent();
        },
    }
</script>